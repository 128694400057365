import repository from './repository'

const resource = 'revenue-sources'

export default {
  index(params) {
    return repository.get(resource, { params })
  },
  store(data) {
    return repository.post(resource, data)
  },
  update(id, data) {
    return repository.put(`${resource}/${id}`, data)
  },
  revenues(interval = '1W', params) {
    return repository.get(`${resource}/${interval}/revenues`, { params })
  },
  collections(interval = '1W', params) {
    return repository.get(`${resource}/${interval}/collections`, { params })
  },
  duckEggCollectors(params) {
    return repository.get(`${resource}/duck-egg-collectors`, { params })
  },
}
