<template lang="pug">
  div
    v-data-table(
      :items="revenueSourceList"
      :headers="headers"
      :loading="revenueSourceGetting"
    )
      template(v-slot:item="{ item }")
        tr
          td {{ item.code }}
          td {{ item.description }}
          td
            base-tooltip(
              color="yellow darken-3"
              small
              text
              tooltip="edit"
              dark
              bottom
              @click="editRevenueSource(item)"
            )
              v-icon mdi-pencil

</template>
<script>
import revenueSourceRepository from '@/repositories/revenue-source.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [revenueSourceGetVars, revenueSourceGetVarNames] = requestVars({ identifier: 'revenue-source' })
const revenueSourceGetHandler = new VueRequestHandler(null, revenueSourceGetVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Code', value: 'code' },
    { text: 'Description', value: 'description' },
    { text: 'Config', sortable: false },
  ],
})

export default {
  name: 'TableRevenueSource',
  props: {
    value: {
      type: Object,
      default: () => ({ }),
    },
  },
  created () {
    this.getRevenueSources()
    this.initWebsocket()
  },
  data () {
    return {
      ...tableVars(),
      ...revenueSourceGetVars,
    }
  },
  methods: {
    getRevenueSources () {
      const handler = revenueSourceGetHandler
      const repository = revenueSourceRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    dbStoreEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'RevenueSource') {
        this.revenueSourceList.push(data)
      }
    },
    dbUpdateEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'RevenueSource') {
        this.reveneSourceDBUpdateEvent(data)
      }
    },
    editRevenueSource (item) {
      this.$emit('input', item)
    },
    reveneSourceDBUpdateEvent (data) {
      this.revenueSourceList = this.revenueSourceList.map(item => {
        if (data.id === item.id) {
          return Object.assign({ ...item, ...data })
        }
        return Object.assign({ ...item })
      })
    },
  },
}
</script>